.image-title {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #ffffff;
    font-size: 16;
    font-weight: 600;
    border-radius: 10px;
    border: 2px solid;
    background-color: #000000;
    padding: 5px;
    z-index: 10;
}

.color-box {
    border-radius: 4px;
    display: table;
    float: left;
    height: 50px;
    width: 50px;
    margin: 5px;
    padding: 5px;
    word-break: break-word;
    text-align: center;
    line-height: 50px;
    font-weight: bolder;
    font-size: 20px;
    cursor: pointer;
}